/*
 * @Description:
 * @Author: 周强
 * @Date: 2021-12-16 15:09:55
 * @LastEditTime: 2022-01-04 11:33:45
 * @LastEditors:
 */
import BasicLayout from "@/components/BasicLayout.vue";
const routers = [
  {
    path: "/",
    component: BasicLayout,
    children: [
      {
        path: "",
        name: "index",
        component: () =>
          import(/* webpackChunkName: "juruipay" */ "@/view/index"),
        meta: {
          title: "聚瑞云控",
        },
      },
    ],
  },
  {
    path: "/pdf",
    component: BasicLayout,
    children: [
      {
        path: "",
        name: "pdf",
        component: () =>
          import(/* webpackChunkName: "juruipay" */ "@/view/pdfview/index"),
        meta: {
          title: "pdf预览",
        },
      },
    ],
  },
  {
    path: "/holiday",
    name: "holiday",
    component: BasicLayout,
    redirect: "/",
    children: [
      {
        path: "spring",
        name: "chunjieIndex",
        component: () =>
          import(
            /* webpackChunkName: "juruipay" */ "@/view/holiday/springFestival/index"
          ),
        meta: {
          title: "春节礼品兑换",
        },
      },
      {
        path: "autumn",
        name: "mid_autumn",
        component: () =>
          import(
            /* webpackChunkName: "juruipay" */ "@/view/holiday/mid_autumn/index"
          ),
        meta: {
          title: "中秋礼品兑换",
        },
      },
      {
        path: "welfare",
        name: "mid_autumn",
        component: () =>
          import(
            /* webpackChunkName: "juruipay" */ "@/view/holiday/employee_welfare/index"
          ),
        meta: {
          title: "员工福利",
        },
      },
      {
        path: "download",
        name: "download",
        component: () =>
          import(/* webpackChunkName: "juruipay" */ "@/view/holiday/download"),
        meta: {
          title: "兑换记录下载",
        },
      },
    ],
  },
  {
    path: "/juruipay",
    name: "home",
    component: BasicLayout,
    redirect: "/juruipay/index",
    children: [
      {
        path: "index",
        name: "pay",
        component: () =>
          import(/* webpackChunkName: "juruipay" */ "@/view/juruipay/index"),
        meta: {
          title: "服务信息",
        },
      },
      {
        path: "complete",
        name: "completePay",
        component: () =>
          import(
            /* webpackChunkName: "juruipay" */ "@/view/juruipay/completePay"
          ),
        meta: {
          title: "完成支付",
        },
      },
    ],
  },
  {
    path: "/Catalogue",
    hidden: true,
    component: BasicLayout,
    redirect: "/Catalogue/serviceList",
    children: [
      {
        path: "serviceList",
        name: "ServiceList",
        component: () =>
          import(
            /* webpackChunkName: "Catalogue" */ "@/view/catalogue/serviceList"
          ),
        meta: {
          title: "服务列表",
        },
      },
      {
        path: "phoneCatalogue",
        name: "PhoneCatalogue",
        component: () =>
          import(
            /* webpackChunkName: "Catalogue" */ "@/view/catalogue/phoneCatalogue"
          ),
        meta: {
          title: "设置服务信息",
        },
      },
      {
        path: "payQr",
        name: "payQr",
        component: () =>
          import(/* webpackChunkName: "Catalogue" */ "@/view/catalogue/payQr"),
        meta: {
          title: "收款二维码",
        },
      },
    ],
  },
  {
    path: "/sign",
    name: "sign",
    component: BasicLayout,
    redirect: "/sign/auth",
    children: [
      {
        path: "auth",
        name: "auth",
        meta: {
          title: "实名认证 - 代扣协议签署",
        },
        component: () => import("@/view/sign/auth"),
      },
      {
        path: "sms_verification",
        name: "smsVerification",
        meta: {
          title: "短息验证码验证 - 代扣协议签署",
        },
        component: () => import("@/view/sign/sms_verification"),
      },
      {
        path: "bank_cardInformation",
        name: "bankCardInformation",
        meta: {
          title: "绑定银行卡 - 代扣协议签署",
        },
        component: () => import("@/view/sign/bank_cardInformation"),
      },
      {
        path: "bank_cardverification",
        name: "bankCardVerification",
        meta: {
          title: "绑定银行卡 - 代扣协议签署",
        },
        component: () => import("@/view/sign/bank_cardverification"),
      },
      {
        path: "agreement",
        name: "agreement",
        meta: {
          title: "确认协议 - 代扣协议签署",
        },
        component: () => import("@/view/sign/agreement"),
      },
      {
        path: "account",
        name: "account",
        meta: {
          title: "完成 - 代扣协议签署",
        },
        component: () => import("@/view/sign/account"),
      },
      {
        path: "404",
        name: "404",
        meta: {
          title: "页面走失了 - 代扣协议签署",
        },
        component: () => import("@/view/sign/404"),
      },
      {
        path: "result",
        name: "result",
        meta: {
          title: "支付结果",
        },
        component: () => import("@/view/sign/result"),
      },
      {
        path: "callback",
        name: "callback",
        meta: {
          title: "签约结果",
        },
        component: () => import("@/view/sign/callback"),
      },
      {
        path: "unbind_code",
        name: "unBindCode",
        meta: {
          title: "解绑手机号验证码 - 延保代签",
        },
        component: () => import("@/view/sign/unbind_mobile"),
      },
      {
        path: "update_mobile",
        name: "updateMobile",
        meta: {
          title: "修改手机号码 - 延保代签",
        },
        component: () => import("@/view/sign/update_mobile"),
      },
    ],
  },
  {
    path: "/extended",
    name: "extendedWarranty",
    component: BasicLayout,
    redirect: "/extended/select_product",
    children: [
      {
        path: "select_product",
        name: "selectProduct",
        meta: {
          title: "选择产品 - 延保代签",
        },
        component: () => import("@/view/extended_warranty/product_list"),
      },
      {
        path: "create_order",
        name: "createOrder",
        meta: {
          title: "录单 - 延保代签",
        },
        component: () => import("@/view/extended_warranty/new_order"),
      },
      {
        path: "confirm_pay",
        name: "confirmPay",
        meta: {
          title: "确认支付 - 延保代签",
        },
        component: () => import("@/view/extended_warranty/confirm_pay"),
      },
      {
        path: "customer_comfirm",
        name: "customerComfirm",
        meta: {
          title: "分期选择 - 延保代签",
        },
        component: () => import("@/view/extended_warranty/customer/select_fq"),
      },
    ],
  },
  {
    path: "/verification",
    name: "verification",
    component: BasicLayout,
    redirect: "/verification/index",
    children: [
      {
        path: "index",
        name: "index",
        meta: {
          title: "保单验真 - 保单查询",
        },
        component: () => import("@/view/verification/search_service"),
      },
      {
        path: "product",
        name: "product",
        meta: {
          title: "保单验真 - 产品列表",
        },
        component: () => import("@/view/verification/service_product"),
      },
      {
        path: "detail",
        name: "detail",
        meta: {
          title: "保单验真 - 保单详情",
        },
        component: () => import("@/view/verification/service_detail"),
      },
      {
        path: "claimApply",
        name: "claimApply",
        meta: {
          title: "理赔申请",
        },
        component: () => import("@/view/verification/claim_apply"),
      },
      {
        path: "orderDetails",
        name: "orderDetails",
        meta: {
          title: "订单详情",
        },
        component: () => import("@/view/verification/order_details"),
      },
    ],
  },
  {
    path: "/wechat_pay",
    name: "wechatPay",
    component: BasicLayout,
    redirect: "/wechat_pay/index",
    children: [
      {
        path: "index",
        name: "index",
        meta: {
          title: "销售端下单支付",
        },
        component: () => import("@/view/wechat_pay/index"),
      },
      {
        path: "confirm_installment_info",
        name: "confirmInstallmentInfo",
        meta: {
          title: "分期信息确认",
        },
        component: () => import("@/view/wechat_pay/confirm_installment_info"),
      },
      {
        path: "bind_card",
        name: "bindCard",
        meta: {
          title: "通联绑卡签约",
        },
        component: () => import("@/view/wechat_pay/bind_card"),
      },
    ],
  },
  {
    path: "/connect",
    name: "Device",
    component: BasicLayout,
    redirect: "/",
    children: [
      {
        path: "index",
        name: "index",
        component: () =>
          import(
            /* webpackChunkName: "juruipay" */ "@/view/device/showLocatioin"
          ),
        meta: {
          title: "设备最新定位",
        },
      },
      {
        path: "contrail",
        name: "contrail",
        component: () =>
          import(
            /* webpackChunkName: "juruipay" */ "@/view/device/contrail/index"
          ),
        meta: {
          title: "设备轨迹回放",
        },
      },
    ],
  },
  {
    path: "/course",
    name: "Course",
    component: BasicLayout,
    children: [
      {
        path: "study",
        name: "Study",
        component: () =>
          import(
            /* webpackChunkName: "juruipay" */ "@/view/course/study/index.vue"
          ),
        meta: {
          title: "课程学习",
        },
      },
      {
        path: "video",
        name: "Video",
        component: () =>
          import(
            /* webpackChunkName: "juruipay" */ "@/view/course/study/video.vue"
          ),
        meta: {
          title: "课程学习",
        },
      },

      {
        path: "examination",
        name: "Examination",
        component: () =>
          import(
            /* webpackChunkName: "juruipay" */ "@/view/course/examination/index.vue"
          ),
        meta: {
          title: "考试管理",
        },
      },
      {
        path: "question",
        name: "Question",
        component: () =>
          import(
            /* webpackChunkName: "juruipay" */ "@/view/course/examination/question.vue"
          ),
        meta: {
          title: "考试",
        },
      },
      {
        path: "result",
        name: "Result",
        component: () =>
          import(
            /* webpackChunkName: "juruipay" */ "@/view/course/examination/result.vue"
          ),
        meta: {
          title: "考试",
        },
      },
    ],
  },
  {
    path: "/protocol",
    name: "Protocol",
    component: BasicLayout,
    redirect: "/",
    children: [
      {
        path: "laf",
        name: "laf",
        component: () =>
          import(/* webpackChunkName: "juruipay" */ "@/view/app_protocol/laf"),
        meta: {
          title: "用户协议",
        },
      },
      {
        path: "privacypolicy",
        name: "privacypolicy",
        component: () =>
          import(
            /* webpackChunkName: "juruipay" */ "@/view/app_protocol/privacypolicy"
          ),
        meta: {
          title: "隐私协议",
        },
      },
    ],
  },
  {
    path: "/gj",
    name: "Gj",
    component: BasicLayout,
    children: [
      {
        path: "/gj/webview/powerInterests",
        name: "PowerInterests",
        component: () => import("@/view/gj/webview/powerInterests1"),
      },
      {
        path: "/gj/app/downLoad",
        name: "GjAppDownLoad",
        component: () => import("@/view/gj/app/downLoad.vue"),
      },
    ],
  },
  {
    path: "/mc",
    name: "mc",
    component: BasicLayout,
    children: [
      {
        path: "/mc/privacypolicy",
        name: "privacypolicy",
        component: () => import("@/view/mc/privacypolicy"),
        meta: {
          title: "隐私政策",
        }
      },
      {
        path: "/mc/serviceAgreement",
        name: "serviceAgreement",
        component: () => import("@/view/mc/serviceAgreement"),
        meta: {
          title: "服务协议",
        }
      },
    ],
  },
  {
    path: "/jury",
    name: "Gj",
    component: BasicLayout,
    children: [
      {
        path: "/juryApp/downLoad",
        name: "PowerInterests",
        component: () => import("@/view/juryApp/downLoad"),
      },
    ],
  },
  {
    path: "/carvip",
    name: "Gj",
    component: BasicLayout,
    children: [],
  },

  {
    path: "/credit",
    component: BasicLayout,
    children: [
      {
        path: "/credit/fillBasicInfo",
        name: "fill_basic_info",
        component: () =>
          import("@/view/credit/real_person_auth/fill_basic_info"),
        meta: {
          title: "基本信息填写",
        },
      },
      {
        path: "/credit/paishe",
        name: "fill_basic_info",
        component: () => import("@/view/credit/real_person_auth/paishe"),
        meta: {
          title: "基本信息填写",
        },
      },
      {
        path: "/auth/:orderNo",
        name: "real_person_auth",
        component: () => import("@/view/credit/real_person_auth/index"),
        meta: {
          title: "实人认证",
        },
      },
      {
        path: "/credit/result",
        name: "result",
        component: () => import("@/view/credit/real_person_auth/result"),
        meta: {
          title: "认证结果",
        },
      },
    ],
  },
];
export default routers;
